import axios from "axios";

const baseUrl = process.env.REACT_APP_IN_API_URL;

export const GetCustomerDetails = async () => {
  const url = window.location.href;
  let clientToken = url.substring(url.lastIndexOf('/') + 1);

  console.log(baseUrl);
  const result = await axios.get(
    baseUrl + clientToken
  );
  if(result.data.error == false) {
    const payload = result.data.data;
    const customerDetails = {
      accountNumber: payload.account_number,
      amountDue: payload.amount_due.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      referenceNumber: payload.reference_number,
      dueDate: payload.due_date,
      fullName: payload.name,
      token: clientToken,
      cards: payload.cards
    };
    localStorage.setItem('customer-details', JSON.stringify(customerDetails));
    return customerDetails;
  } else {
    alert("We are unable to retrieve your account information. Please try again or contact support.");
  }

  return {};
};

export const LoadCustomerDetails = async () => {
  const customerDetails = localStorage.getItem('customer-details');
  return await JSON.parse(customerDetails);
};