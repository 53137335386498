import "./card-payment-option.scss";
import mastercardLogo from "../../assets/mastercard-logo.png";
import visaLogo from "../../assets/visa-logo.png";
import deleteIcon from "../../assets/delete-icon.png";

const CardPaymentOption = ({
  cardType,
  cardDetails,
  isSelectedRow,
  handleDeleteCardPressed,
  handleSelectCardPressed
}) => {
  return (
    <div
      class="card-payment-option"
      style={{ backgroundColor: !isSelectedRow ? "white" : "#E3E3E3" }}
    >
      <div class="flex-row-box">
        <div
          class="card-radio-button"
          onClick={() => handleSelectCardPressed(cardDetails)}
        >
          {isSelectedRow && <div class="card-radio-selection"></div>}
        </div>
        <div class="card-type-logo-box">
          {cardType === "master" && (
            <img alt="" src={mastercardLogo} class="card-type-smaller-logo" />
          )}
          {cardType === "visa" && (
            <img alt="" src={visaLogo} class="card-type-logo" />
          )}
        </div>
        <div class="card-number-text">
          {"**** **** **** " + cardDetails.card_last_four}
        </div>
      </div>
      <div class="flex-row-box">
        <div
          to="/delete-card"
          class="delete-card-box"
          onClick={() => handleDeleteCardPressed(cardDetails)}
        >
          <img alt="" src={deleteIcon} class="delete-icon" />
        </div>
      </div>
    </div>
  );
};

export default CardPaymentOption;
