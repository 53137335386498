import axios from "axios";

// const baseUrl = "https://xpayapi.lunchcard.co.za/";
const baseUrl = process.env.REACT_APP_PP_API_URL;

export const InitiatePayment = async (paymentDetails) => {
  console.log(paymentDetails);
  const result = await axios.post(
    baseUrl + "payment/initiate-payment",
    paymentDetails
  );
  return result.data;
};

export const GetStoredCards = async (accountNumber) => {
  const result = await axios.get(
    baseUrl + "payment/get-stored-cards?accountNumber=" + accountNumber
  );
  return result.data;
};

export const DeleteStoredCard = async (storedCardRef) => {
  const result = await axios.delete(
    baseUrl + "payment/delete-card?cardRef=" + storedCardRef
  );
  return result.data;
};

export const GetPaymentResult = async (paymentReference, paymentType) => {
  try {
    const result = await axios.get(
      baseUrl +
        "payment/validate-payment?paymentId=" +
        paymentReference +
        "&paymentType=" +
        paymentType
    );
    return result;
  } catch (e) {
    console.log("e");
    return e;
  }
};

export const MakePayment = async (paymentDetails) => {
  const result = await axios.post(
    baseUrl + "payment/make-payment",
    paymentDetails
  );
  return result.data;
};
