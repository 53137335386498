import BalanceBox from "../../components/balance-box/balance-box";
import "./home-screen.scss";
import yoyoLogo from "../../assets/yoyo-logo.png";
import PaymentSuccessfulScreen from "../payment-successful-screen/payment-successful-screen";
import PaymentUnsuccessfulScreen from "../payment-unsuccessful-screen/payment-unsuccessful-screen";
import DeleteCardScreen from "../delete-card-screen/delete-card-screen";
import {
  InitiatePayment,
  GetStoredCards,
  DeleteStoredCard,
  MakePayment,
} from "../../libs/paymentApi";
import { useEffect, useState } from "react";
import {
  GetCustomerDetails,
  LoadCustomerDetails,
} from "../../libs/customerApi";
import WideButton from "../../components/wide-button/wide-button";
import CardPaymentOption from "../../components/card-payment-option/card-payment-option";
import { useHistory, useParams } from "react-router-dom";
import PayNowScreen from "../pay-now-screen/pay-now-screen";
import axios from "axios";

const HomeScreen = ({
  showPaymentSuccessfulScreen,
  showPaymentUnsuccessfulScreen,
  paymentResultData,
  paymentReference,
  setCurrentPaymentId,
  showPayNowScreen,
  setPaymentResultData,
  customerDetails,
  setCustomerDetails,
}) => {
  const [storedCards, setStoredCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [showDeleteCardScreen, setShowDeleteCardScreen] = useState(false);
  const [deleteCardLoading, setDeleteCardLoading] = useState(false);
  const history = useHistory(); // eslint-disable-line react-hooks/exhaustive-deps
  const [redirectData, setRedirectData] = useState({});

  const params = useParams();
  useEffect(() => {
    if (params.clientToken) {
      getCustomerDetails();
    } else {
      loadCustomerDetails();
    }
  }, []);

  const getCustomerDetails = async () => {
    const result = await GetCustomerDetails();
    setCustomerDetails(result);
  };

  const loadCustomerDetails = async () => {
    const result = await LoadCustomerDetails();
    setCustomerDetails(result);
  };

  useEffect(() => {
    if (paymentResultData && paymentResultData.redirect) {
      const redirectData = parseResultData(paymentResultData.redirect);
      setRedirectData(redirectData);
    }
  }, [paymentResultData]);

  useEffect(() => {
    if (redirectData.url) {
      history.push("/pay-now/" + paymentResultData.id);
    }
  }, [redirectData]);

  useEffect(() => {
    if (customerDetails.accountNumber) {
      getStoredCards(customerDetails.accountNumber);
    }
  }, [customerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoredCards = async () => {
    setStoredCards(customerDetails.cards);
    if(customerDetails.cards.length) {
      setSelectedCard(customerDetails.cards[0]);
    }
    /*
    const result = await GetStoredCards(customerDetails.accountNumber);
    setStoredCards(result);
    if (result) {
      setSelectedCard(result[0]);
    }
    */
  };

  const handleAddCardPressed = async () => {
    const paymentDetailsDto = {
      amount: customerDetails.amountDue.toString(),
      accountNumber: customerDetails.accountNumber,
    };
    const result = await InitiatePayment(paymentDetailsDto);
    await setCurrentPaymentId(result.id);
    history.push("/add-card/" + result.id);
  };

  const handleDeleteCardPressed = async (selectedCard) => {
    setShowDeleteCardScreen(true);
    setSelectedCard(selectedCard);
  };

  const handleCancelDeleteCardPressed = async () => {
    setShowDeleteCardScreen(false);
  };

  const handleConfirmDeleteCardPressed = async () => {
    setDeleteCardLoading(true);
    try {
      // await DeleteStoredCard(selectedCard.storedCardRef);
      const result = await axios.post(
        process.env.REACT_APP_IN_API_URL + customerDetails.token + '/' + selectedCard.id
      );
      getCustomerDetails();
      setShowDeleteCardScreen(false);
    } catch {
    } finally {
      setDeleteCardLoading(false);
    }
    await getStoredCards();
  };

  const handleSelectCardPressed = (selection) => {
    setSelectedCard(selection);
  };

  const handlePayNowPressed = async () => {
    const paymentDetailsDto = {
      amount: customerDetails.amountDue.toString(),
      accountNumber: customerDetails.accountNumber,
      cardToken: selectedCard ? selectedCard.storedCardRef : "",
    };
    if (paymentDetailsDto.cardToken) {
      const result = await MakePayment(paymentDetailsDto);
      if (result) {
        await setPaymentResultData(result);
      }
    } else {
      const result = await InitiatePayment(paymentDetailsDto);
      await setCurrentPaymentId(result.id);
      history.push("/add-card/" + result.id);
    }
  };

  const parseResultData = (redirectData) => {
    let parsedData = {};
    const redirectParameters = redirectData.parameters;
    parsedData.url = redirectData.url;
    parsedData.paReq = redirectParameters.find((x) => x.name === "PaReq").value;
    parsedData.connector = redirectParameters.find(
      (x) => x.name === "connector"
    ).value;
    parsedData.md = redirectParameters.find((x) => x.name === "MD").value;
    parsedData.termUrl = redirectParameters.find(
      (x) => x.name === "TermUrl"
    ).value;

    return parsedData;
  };

  return (
    <div class="content-box">
      <div class="account-selection-box">
        <BalanceBox customerDetails={customerDetails} />
        {storedCards.length > 0 &&
          storedCards.map((storedCard, i) => {
            return (
              <CardPaymentOption
                key={i}
                cardType={storedCard.payment_brand}
                isSelectedRow={
                  selectedCard.reference_number === storedCard.reference_number
                }
                handleSelectCardPressed={handleSelectCardPressed}
                cardDetails={storedCard}
                handleDeleteCardPressed={handleDeleteCardPressed}
              />
            );
          })}
        {storedCards.length > 0 && (
          <div onClick={() => handleAddCardPressed()}>
            <div class="link-button-text">+ PAY WITH ANOTHER CARD</div>
          </div>
        )}
        <WideButton buttonText="Pay Now" onClickAction={handlePayNowPressed} />
        <div class="bottom-logo-box">
          <img alt="" src={yoyoLogo} class="bottom-logo" />
        </div>
      </div>
      {showPaymentSuccessfulScreen && (
        <PaymentSuccessfulScreen
          paymentResultData={paymentResultData}
          paymentReference={paymentReference}
        />
      )}
      {showPayNowScreen && <PayNowScreen redirectData={redirectData} />}
      {showPaymentUnsuccessfulScreen && (
        <PaymentUnsuccessfulScreen paymentResultData={paymentResultData} />
      )}
      {showDeleteCardScreen && (
        <DeleteCardScreen
          handleDeleteCardPressed={handleConfirmDeleteCardPressed}
          cardDetails={selectedCard}
          customerDetails={customerDetails}
          isLoading={deleteCardLoading}
          handleCancelDeleteCardPressed={handleCancelDeleteCardPressed}
        />
      )}
    </div>
  );
};

export default HomeScreen;
