import "./virtual-card.scss";
import mastercardLogo from "../../assets/mastercard-logo.png";
import visaLogo from "../../assets/visa-logo.png";
import yoyoLogoWhite from "../../assets/yoyo-logo-white.png";

const VirtualCard = ({ cardDetails, customerDetails }) => {
  // For development purposes only.
  if(!cardDetails.payment_brand) {
    cardDetails.payment_brand = 'VISA';
  }
  return (
    <div class="virtual-card-box">
      <div class="virtual-card-top-box">
        <div class="virtual-card-type-box">
          <div class="virtual-card-type-logo">
            {cardDetails.payment_brand.toLowerCase() === "master" && (
              <img src={mastercardLogo} height="16" alt="" />
            )}
            {cardDetails.payment_brand.toLowerCase() === "visa" && (
              <img src={visaLogo} height="16" alt="" />
            )}
            <img src={visaLogo} height="16" alt="" />
          </div>
          <div class="virtual-card-type-text">{customerDetails.payment_brand}</div>
        </div>
        <div class="virtual-card-yoyo-logo-box">
          <img src={yoyoLogoWhite} alt="" />
        </div>
      </div>
      <div class="virtual-card-card-number-heading">Card Number</div>
      <div class="virtual-card-card-number-text">**** **** **** {cardDetails.card_last_four}</div>
      <div class="virtual-card-lower-details-box">
        <div class="virtual-card-lower-text-row">
          <div class="virtual-card-lower-text">Card Holder Name</div>
          <div class="virtual-card-lower-text">Expiration</div>
        </div>
        <div class="virtual-card-lower-text-row">
          <div class="virtual-card-lower-text">{customerDetails.fullName}</div>
          <div class="virtual-card-lower-text">
            {cardDetails.card_expire.substring(0, 2)}
            {cardDetails.card_expire.substring(2, 7)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualCard;
