import CheckBox from "../../components/check-box/check-box";
import BalanceBox from "../../components/balance-box/balance-box";
import "./add-card-screen.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/close-icon.png";
import { LoadCustomerDetails } from "../../libs/customerApi";

const AddCardScreen = ({
  currentPaymentId,
  customerDetails,
  setCustomerDetails
}) => {
  const [saveCardChecked, setSaveCardChecked] = useState(true);
  const [showSaveCard, setShowSaveCard] = useState(false);

  const handleCheckClicked = () => {
    setSaveCardChecked(!saveCardChecked);
  };

  const handleClickForm = () => {
    setShowSaveCard(true);
  };

  useEffect(() => {
    loadCustomerDetails();

    const script = document.createElement("script");
    script.src =
      "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=" +
      currentPaymentId;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const loadCustomerDetails = async () => {
    const result = await LoadCustomerDetails();
    console.log(result);
    setCustomerDetails(result);
  };

  return (
    <div class="content-box">
      <div class="account-selection-box">
        <BalanceBox customerDetails={customerDetails} />
        <div class="add-card-screen-modal add-shading">
          <Link to="/" class="close-icon-box">
            <img src={closeIcon} alt="" />
          </Link>
          <div class="bold-centered-heading-text">Add debit / credit card details</div>
          <div class="add-payment-form" onClick={() => handleClickForm()}>
            <form
              action="https://x-pay.fluenty.co.za/payment-complete/regular"
              class="paymentWidgets"
              data-brands="VISA MASTER AMEX"
            ></form>
          </div>
          {showSaveCard && (
            <div class="add-card-screen-checkbox">
              <CheckBox
                lableText="Save card for future payments"
                handleCheckClicked={handleCheckClicked}
                checkValue={saveCardChecked}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCardScreen;
