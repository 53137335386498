import { useEffect, useState } from "react";
import BalanceBox from "../../components/balance-box/balance-box";
import PaymentSuccessfulScreen from "../payment-successful-screen/payment-successful-screen";
import "./validating-payment-screen.scss";
import { GetPaymentResult } from "../../libs/paymentApi";
import { LoadCustomerDetails } from "../../libs/customerApi";
import { GetQueryVariable } from "../../libs/general";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

const ValidatingPaymentScreen = ({
  setPaymentResultData,
  setPaymentReference,
}) => {
  const history = useHistory();
  const { paymentType } = useParams();
  const [customerDetails, setCustomerDetails] = useState({});
  const [validatingPayment, setValidatingPayment] = useState(true);
  const [paymentResult, setPaymentResult] = useState(""); // eslint-disable-line react-hooks/exhaustive-deps

  const validatePayment = async () => {
    setValidatingPayment(true);
    const paymentReference = await GetQueryVariable("id");
    setPaymentReference(paymentReference);
    let result = null;
    try {
      result = await GetPaymentResult(paymentReference, paymentType);
    } catch {
      history.push("/payment-unsuccessful");
    }
    if (result.status === 200) {
      await setPaymentResultData(result.data);
      // Send payment details to invoice ninja.
      const customerDetails = await LoadCustomerDetails();
      console.log(result.data);
      const payload = {
        id: result.data.id,
        amount: result.data.amount,
        registrationId: result.data.registrationId,
        paymentBrand: result.data.paymentBrand,
        expiryMonth: result.data.card.expiryMonth,
        expiryYear: result.data.card.expiryYear,
        holder: result.data.card.holder,
        last4Digits: result.data.card.last4Digits,
        code: result.data.result.code,
        description: result.data.result.description
      };
      console.log(payload);
      axios.post(process.env.REACT_APP_IN_API_URL + customerDetails.token, payload)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
      history.push("/payment-successful");
    } else {
      await setPaymentResultData(result.response.data);
      history.push("/payment-unsuccessful");
    }
    setValidatingPayment(false);
  };

  useEffect(() => {
    validatePayment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div class="content-box">
      <div class="account-selection-box">
        <BalanceBox customerDetails={customerDetails} />
      </div>
      {validatingPayment && (
        <div class="validation-box">
          <div class="validating-payment-heading">Validating payment...</div>
          <div class="loader"></div>
        </div>
      )}
      {paymentResult === "success" && <PaymentSuccessfulScreen />}
      {paymentResult === "failed" && (
        <div class="payment-successful">Payment failed</div>
      )}
    </div>
  );
};

export default ValidatingPaymentScreen;
