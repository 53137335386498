import "./pay-now-screen.scss";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/close-icon.png";
import { useParams } from "react-router-dom";

const PayNowScreen = ({ redirectData }) => {
  const peachPaymentForm = useRef();
  useEffect(() => {
    setTimeout(() => {
      peachPaymentForm.current.submit();
    }, 1000);
  }, []);

  return (
    <div class="background-screen-box">
      <div class="add-card-screen-modal add-shading">
        <Link to="/" class="close-icon-box">
          <img src={closeIcon} alt="" />
        </Link>
        <div class="bold-centered-heading-text">Processing payment...</div>
        <div class="add-payment-form">
          <form
            name="form"
            ref={peachPaymentForm}
            action={redirectData.url}
            target="_self"
            method="POST"
          >
            <input type="hidden" name="MD" value={redirectData.md} />
            <input type="hidden" name="PaReq" value={redirectData.paReq} />
            <input type="hidden" name="TermUrl" value={redirectData.termUrl} />
            <input
              type="hidden"
              name="connector"
              value={redirectData.connector}
            />
            <script>
              <input type="submit" value="Click here to continue" />
            </script>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PayNowScreen;
