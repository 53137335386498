import "./delete-card-screen.scss";
import WideButton from "../../components/wide-button/wide-button";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/close-icon.png";
import VirtualCard from "../../components/virtual-card/virtual-card";

const DeleteCardScreen = ({
  handleDeleteCardPressed,
  cardDetails,
  customerDetails,
  isLoading,
  handleCancelDeleteCardPressed,
}) => {
  return (
    <div class="background-screen-box">
      <div class="payment-successful-screen-modal">
        <Link to="/" class="close-icon-box">
          <img src={closeIcon} alt="" />
        </Link>
        <div class="bold-centered-heading-text">Delete Card</div>
        <VirtualCard
          cardDetails={cardDetails}
          customerDetails={customerDetails}
        />
        <div class="delete-card-text">
          Are you sure you want to delete this card?
          Once deleted this card will no longer be available.
        </div>
        <div class="delete-card-buttons-box">
          <div class="delete-card-button">
            <WideButton
              buttonText="Delete"
              alternateStyle={true}
              staticMargins={true}
              onClickAction={handleDeleteCardPressed}
              isLoading={isLoading}
            />
          </div>
          <div
            class="delete-card-button"
            onClick={() => handleCancelDeleteCardPressed()}
          >
            <WideButton buttonText="Cancel" staticMargins={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCardScreen;
