import "./check-box.scss";

const CheckBox = ({ lableText, handleCheckClicked, checkValue }) => {
  return (
    <div class="check-box-parent-box" onClick={handleCheckClicked}>
      <input type="checkbox" class="check-box" checked={checkValue} />
      <div class="check-box-lable">{lableText}</div>
    </div>
  );
};

export default CheckBox;
