import "./App.scss";
import HomeScreen from "./screens/home-screen/home-screen";
import Header from "./components/header/header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ValidatingPaymentScreen from "./screens/validating-payment-screen/validating-payment-screen";
import { useState } from "react";
import AddCardScreen from "./screens/add-card-screen/add-card-screen";

function App() {
  const [paymentResultData, setPaymentResultData] = useState({});
  const [paymentReference, setPaymentReference] = useState("");
  const [currentPaymentId, setCurrentPaymentId] = useState("");
  const [storedCardToken, setStoredCardToken] = useState("");
  const [customerDetails, setCustomerDetails] = useState({});

  return (
    <div className="app">
      <Header />
      <Router>
        <Switch>
          <Route path="/payment-complete/:paymentType">
            <ValidatingPaymentScreen
              setPaymentResultData={setPaymentResultData}
              setPaymentReference={setPaymentReference}
            />
          </Route>
          <Route path="/payment-successful">
            <HomeScreen
              showPaymentSuccessfulScreen={true}
              paymentResultData={paymentResultData}
              paymentReference={paymentReference}
              setStoredCardToken={setStoredCardToken}
              storedCardToken={storedCardToken}
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
          </Route>
          <Route path="/payment-unsuccessful">
            <HomeScreen
              showPaymentUnsuccessfulScreen={true}
              paymentResultData={paymentResultData}
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
          </Route>
          <Route path="/delete-card">
            <HomeScreen
              showDeleteCardScreen={true}
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
          </Route>
          <Route path="/add-card/:paymentId">
            <AddCardScreen
              currentPaymentId={currentPaymentId}
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
          </Route>
          <Route path="/pay-now">
            <HomeScreen
              showPayNowScreen={true}
              setCurrentPaymentId={setCurrentPaymentId}
              currentPaymentId={currentPaymentId}
              paymentResultData={paymentResultData}
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
          </Route>
          
          <Route path="/:clientToken">
            <HomeScreen
              showAddCardScreen={false}
              setCurrentPaymentId={setCurrentPaymentId}
              currentPaymentId={currentPaymentId}
              setStoredCardToken={setStoredCardToken}
              storedCardToken={storedCardToken}
              setPaymentResultData={setPaymentResultData}
              paymentResultData={paymentResultData}
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
            </Route>
            <Route path="/">
              <HomeScreen
                showAddCardScreen={false}
                setCurrentPaymentId={setCurrentPaymentId}
                currentPaymentId={currentPaymentId}
                setStoredCardToken={setStoredCardToken}
                storedCardToken={storedCardToken}
                setPaymentResultData={setPaymentResultData}
                paymentResultData={paymentResultData}
                customerDetails={customerDetails}
                setCustomerDetails={setCustomerDetails}
              />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
