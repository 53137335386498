import "./balance-box.scss";

const BalanceBox = ({ customerDetails }) => {
  return (
    <div class="balance-box">
      <div class="balance-box-child">
        <div class="balance-box-heading-text">Balance Due:</div>
        <div class="balance-box-value-text">R{customerDetails.amountDue}</div>
        <div class="account-number-text">Account Number</div>
        <div class="account-number-value-text">
          {customerDetails.accountNumber}
        </div>
      </div>
      <div class="balance-box-divider"></div>
      <div class="balance-box-child">
        <div class="balance-box-heading-text">Due Date:</div>
        <div class="balance-box-value-text">{customerDetails.dueDate}</div>
        <div class="reference-number-text">Reference Number</div>
        <div class="reference-number-value-text">
          {customerDetails.referenceNumber}
        </div>
      </div>
    </div>
  );
};

export default BalanceBox;
