import "./header.scss";
import headerLogo from "../../assets/rcs-logo.png";
import { useState } from "react";
import infoIcon from "../../assets/info-1x.png";

const Header = () => {
  const [tooltip, setTooltip] = useState(false);

  return (
    <div class="header-box">
      <div class="header-logo-box">
        <img alt="Logo" src={headerLogo} class="header-logo" />
        <div className={"tooltip-icon"} onClick={() => setTooltip(!tooltip)}>
        <img src={infoIcon} alt="" />
        </div>
        {tooltip && (
          <div class="bubble">
            <b>For any enquiries:</b><br /><br />
            <table border="0" cellSpacing="10">
              <tr>
                <td>Call center:</td>
                <td>0861 729 727</td>
              </tr>
              <tr>
                <td>Website:</td>
                <td><a target="_blank" href="https://www.rcs.co.za/">www.rcs.co.za</a></td>
              </tr>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
